@use 'base';

.btn {
  padding: 10px 30px;
  background-color: base.$blue;
  border: 2px solid base.$blue;
  color: white;
  font-weight: bold;
  border-radius: 2px;
  outline: none;
  transition: padding 400ms ease-in-out;
  width: fit-content;
  &:hover {
    padding: 10px 50px;
    background-color: base.$dark-blue;
    border: 2px solid base.$dark-blue;
    cursor: pointer;
    font-weight: bold;
  }
  &:active {
    background-color: white;
    border: 2px solid base.$dark-blue;
    border-radius: 2px;
    color: base.$dark-blue;
  }
}

@media (max-width: 650px) {
  .btn {
    font-size: 1.1rem;
  }
}