@use 'base'; // Sass partial
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@200;300;400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: base.$font-stack;
}

.App {
  min-height: 100%;
  color: base.$dark-grey;
}

h1 {
  // font-family: 'Playfair Display';
  color: base.$dark-grey;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
}

// ----------------------------------------
// REUSABLE CLASSES
// ----------------------------------------
.flex {
  display: flex;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.wrap {
  flex-wrap: wrap;
}
.space-b {
  justify-content: space-between;
}

.bold {
  font-weight: bolder;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

// ----------------------------------------
// NAV / FOOTER
// ----------------------------------------
.navbar,
footer {
  min-height: 8vh;
  background-color: base.$dark-grey;
  color: white;
  padding: 0 10rem;
}

footer {
  justify-content: center;
}

.navbar {
  .brand-name {
    font-weight: bold;
  }
  .links a {
    margin-left: 20px;
  }
}

// ----------------------------------------
// RESPONSIVENESS / PADDING
// ----------------------------------------
@media (min-width: 650px) {
  section {
    padding: 3rem 10rem;
  }
}

@media (max-width: 650px) {
  section {
    padding: 3rem 2rem;
  }
  .navbar,
  footer {
    padding: 0 2rem;
  }
}
