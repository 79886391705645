$font-title: 'Playfair Display' ;
$font-stack: "Poppins", Arial, Helvetica, sans-serif;
$dark-blue: rgb(40, 40, 130);
$blue: rgb(25, 120, 240);
$dark-blue: rgb(22, 110, 240);
$red: #b20a37;
$grey: #ccc;
$light-grey: #f9f9f9;
$not-so-dark-grey: rgba(0, 0, 0, 0.7);
$dark-grey: rgba(0, 0, 0, 0.85);
