@use 'base';

// ----------------------------------------
// ALL SECTIONS
section:not(:first-of-type):nth-of-type(odd) {
  background-color: base.$light-grey;
  // color: white;
  // h1 {
  //   color: white;
  //   border-bottom: 2px solid white;
  // }
}

section:not(:first-of-type) h1 {
  border-bottom: 2px solid base.$dark-grey;
  margin-bottom: 2rem;
  width: fit-content;
  font-size: 2rem;
}

// ----------------------------------------
// GREETINGS
.greetings-section {
  .hello {
    font-size: 3rem;
  }
  min-height: 92vh;
  background-repeat: no-repeat;
  background-size: cover;
  .greetings {
    padding-top: 5rem;
    color: base.$dark-grey;
    h1,
    h2 {
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
}

@media (min-width: 1270px) {
  .greetings-section {
    // background-position: right;
    background-position-y: -50px;
  }
}

@media (min-width: 1060px) and (max-width: 1269px) {
  .greetings-section {
    background-position-y: 80px;
  }
}

@media (min-width: 1030px) and (max-width: 1059px) {
  .greetings-section {
    background-position-y: 100px;
  }
}

@media (min-width: 900px) and (max-width: 1029px) {
  .greetings-section {
    // background-position-x: 100px;
    background-position-y: 120px;
  }
}

@media (min-width: 650px) and (max-width: 899px) {
  // .hello {
  //   font-size: 3rem;
  // }
  .greetings-section {
    background-position-y: 150px;
  }
}

@media (max-width: 650px) {
  .hello {
    font-size: 2.5rem;
  }
  .greetings-section {
    background-position-x: -250px;
    background-position-y: 150px;
  }
}

// ----------------------------------------
// ABOUT SECTION
.about-paragraphs {
  background-color: base.$light-grey;
  padding: 3rem;
  border-radius: 5px;

  .about-text {
    font-weight: 16px;
    margin-bottom: 2rem;
    text-align: center;
  }
}

// ----------------------------------------
// SKILLS SECTION
.stack-container {
  padding: 2rem;
  border-radius: 5px;
  background-color: white;
  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .stack-container {
    padding: 1rem;
    text-align: center;
  }
}

.stack-logos {
  justify-content: space-evenly;
}

.stack-item {
  align-items: center;
  margin: 20px 10px 0 10px;
  p {
    font-weight: bold;
  }
  .logo {
    background-color: white;
    height: 100px;
    padding: 10px;
    border-radius: 1rem;
  }
}

@media (max-width: 600px) {
  .logo {
    height: 60px;
  }
}

// ----------------------------------------
// PROJECT SECTION
.grid-container {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.project-item {
  box-shadow: 0 0 6px base.$grey;
  border-radius: 5px;
  .card-top {
    min-height: 50%;
    .img {
      width: 100%;
      height: 100%;
      border-radius: 5px 5px 0 0;
    }
  }
  .description {
    margin-top: 1rem;
    text-align: center;
  }
  .stack {
    margin-top: 1rem;
    font-weight: lighter;
    text-align: center;
  }
  .card-bottom {
    padding: 1rem;
    align-items: center;
    max-height: 50%;
  }
}

// ----------------------------------------
// CONTACT SECTION
.contact-options {
  align-items: flex-start;
}

.contact-informations {
  min-width: fit-content;
  width: 50%;
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  h3 {
    margin-top: 1rem;
    // color: base.$dark-blue;
  }
  a {
    color: base.$blue;
    margin: 5px;
    transition: transform 0.8s ease-in-out;
  }
  a:hover {
    color: base.$dark-blue;
    transform: rotateZ(360deg);
  }
}

.contact-form {
  border-radius: 2px;
  width: 40%;
  input,
  textarea {
    padding: 10px;
    border: 1px solid base.$grey;
    border-radius: 2px;
    min-width: 300px;
    margin-bottom: 1rem;
  }
}

// ----------------------------------------
// RESPONSIVENESS : CONTACT SECTION
@media (max-width: 1000px) {
  .contact-options {
    flex-direction: column;
  }
  .contact-form {
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
    button {
      margin: auto;
    }
  }
  .contact-informations {
    width: 100%;
  }
}
